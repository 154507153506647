@import '../../index.module.scss';

:export {
    offsetX: $popup-offset-x;
    offsetY: $popup-offset-y;
}

.stop-popup {
    .header {
        background-color: #00000010;
        padding: 6px 12px;
        font-weight: 600;
        min-width: 186px;
    }

    .body {
        padding: 12px;

        & > .routes-header {
            margin-top: 0;
            margin-bottom: 7px;
        }

        & > .routes {
            display: flex;
            flex-wrap: wrap;

            &:not(:empty) {
                margin-left: -1px;
                margin-top: -1px;
            }

            & > .route-button {
                box-sizing: border-box;
                overflow-x: hidden;
                padding: 5px 2px;
                white-space: nowrap;
                justify-content: flex-start;
                align-items: center;
                width: 60px;
                margin: 1px;

                svg {
                    width: 18px;
                    height: 18px;
                    margin-right: 3px;
                }
            }
        }

        & > .points {
            display: flex;
            justify-content: center;
            margin-top: 7px;

            & > .point-button {
                & > .title {
                    font-size: 10px;
                    text-align: center;
                    margin-bottom: 3px;
                }

                & > .button {
                    border: 1px solid;
                    border-radius: 50px;
                    width: 29px;
                    height: 29px;
                    margin: auto;
                    padding: 0;
                    background: #fff;
                    border-color: #d2d0ce;

                    & > .letter {
                        font-size: 19px;
                        font-weight: 600;
                        margin-top: -3px;
                        color: #808080;
                    }

                    &:hover {
                        background: #edebe9;
                        border-color: #d2d0ce;

                        & > .letter {
                            color: #3b3a39;
                        }
                    }
                }

                & > .active-a {
                    background: #d29200;
                    border-color: #fbf4e5;

                    & > .letter {
                        color: #fff;
                    }

                    &:hover {
                        background: #c58900;
                        border-color: #fbf4e5;

                        & > .letter {
                            color: #fff;
                        }
                    }
                }

                & > .active-b {
                    background: #5c2d91;
                    border-color: #efeaf4;

                    & > .letter {
                        color: #fff;
                    }

                    &:hover {
                        background: #4d2679;
                        border-color: #efeaf4;

                        & > .letter {
                            color: #fff;
                        }
                    }
                }
            }

            & > .switch-button {
                align-self: flex-end;
                margin: 0 12px 8px 12px;

                svg {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    .footer {
        margin: 0 auto;
        text-align: center;
        padding: 0 6px 6px 6px;
        width: 186px;
    }
}
