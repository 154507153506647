$my-location-circle-fill-color: #69797e;
$map-layout-zoom-snap: 0.3;
$map-locate-button-icon-min: 12;
$map-locate-button-icon-max: 36;

:export {
    myLocationCircleFillColor: $my-location-circle-fill-color;
    zoomSnap: $map-layout-zoom-snap;
    iconSizeMin: $map-locate-button-icon-min;
    iconSizeMax: $map-locate-button-icon-max;
}

.map-layout {
    flex: 1;
    position: relative;
    height: 100%;

    & > :global(.leaflet-container) {
        width: 100%;
        height: 100%;
        font-family: SegoeUI;

        :global(.leaflet-left) {
            display: flex;
        }

        :global(.leaflet-bar) {
            border: none;
            border-radius: 0;

            & > a {
                color: #3b3a39;
                box-shadow: 0 1.6px 3.6px 0 rgba(0,0,0,.132), 0 0.3px 0.9px 0 rgba(0,0,0,.108);
                cursor: pointer;

                &:hover {
                    background-color: #edebe9;
                }

                &:first-child {
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    border-top: 1px solid #d2d0ce;
                    border-left: 1px solid #d2d0ce;
                    border-right: 1px solid #d2d0ce;
                }

                &:last-child {
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    border-bottom: 1px solid #d2d0ce;
                    border-left: 1px solid #d2d0ce;
                    border-right: 1px solid #d2d0ce;
                }
            }
        }

        :global(.leaflet-control-locate) {
            & > a {
                background-image: url('/assets/my-location.svg');
                background-size: contain;
            }

            &:global(.active) > a {
                background-image: url('/assets/my-location-active.svg');
            }
        }

        :global(.menu-button) {
            & > a {
                background-image: url('/assets/menu-list.svg');
                background-size: 20px;
            }

            &:global(.active) {
                display: none;
            }
        }

        :global(.info-button) {
            & > a {
                background-image: url('/assets/info.svg');
                background-size: 20px;
            }

            &:global(.active) {
                display: none;
            }
        }

        :global(.vehicles-button) {
            & > a {
                background-image: url('/assets/vehicles-button.svg');
                background-position: 4px 4px;
                background-size: 23px;
            }

            &:global(.active) {
                & > a {
                    background-image: url('/assets/vehicles-button-active.svg');
                }
            }
        }

        :global(.stops-button) {
            & > a {
                background-image: url('/assets/stops-button.svg');
                background-position: 4px 4px;
                background-size: 22px;
            }

            &:global(.active) {
                & > a {
                    background-image: url('/assets/stops-button-active.svg');
                }
            }
        }
    }

    :global(.leaflet-div-icon) {
        background: none;
        border: none;
    }

    :global(.leaflet-popup-content-wrapper) {
        font-size: 14px;
        font-family: SegoeUI;
        padding: 0;
        border-radius: 5px;
        background-image: url('/assets/leftbar-background.png');
        box-shadow: 0 6.4px 14.4px 0 rgba(0,0,0,.22), 0 1.2px 3.6px 0 rgba(0,0,0,.18);

        & > :global(.leaflet-popup-content) {
            margin: 0;
            line-height: normal;
        }
    }

    :global(.my-location-marker) {
        background-size: cover;
        width: 100%;
        height: 100%;
        background-image: url('/assets/my-location-marker.svg');
    }
}
