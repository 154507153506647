.trip-info-button {
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #d2d0ce;
    background-color: white;

    & > .header {
        display: flex;
        justify-content: center;
        padding: 3px;
        background: #00000010;
        font-size: 12px;

        svg {
            width: 15px;
            height: 15px;
            display: flex;
        }
    }

    &:hover {
        background-color: #edebe9;

        & > .header {
            background: #00000020;
        }

        *[class*='Label_blue__'] {
            background-color: #ccdbe6; // #004e8c 20
        }

        *[class*='Label_green__'] {
            background-color: #cee0ce; // #0b6a0b 20
        }

        *[class*='Label_red__'] {
            background-color: #ebd3d4; // #a4262c 20
        }
        //*[class*='Label_XXX__'] is used to access colored label which cannot be accessed through isolated css
    }

    &.active {
        background-color: #f3f2f1;
        border-color: #bebbb8;
        font-weight: 600;

        &:hover {
            background-color: #e1dfdd;

            & > .header {
                background: #00000020;
            }
        }
    }

    & > .body {
        display: flex;
        padding: 5px 7px;
        align-items: flex-end;

        & > .arrow {
            width: 13px;
            margin: auto 0;

            svg {
                display: flex;
            }
        }

        & > .walk-icon {
            svg {
                margin-bottom: 5px;
            }
        }
    }
}
