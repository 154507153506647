@import '../../../index.module.scss';

:export {
    fadeTransitionDuration: $fade-transition-duration;
}

.transport-menu {
    display: flex;
    flex-direction: column;

    & > .groups-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > .clear-button {
            white-space: nowrap;
            display: flex;
            align-items: center;

            svg {
                width: 10px;
                height: 10px;
                margin-right: 5px;
            }
        }
    }

    .group-button {
        box-sizing: border-box;
        overflow-x: hidden;
        padding: 5px 2px;
        white-space: nowrap;
    }

    .route-button {
        box-sizing: border-box;
        overflow-x: hidden;
        padding: 5px 2px;
        white-space: nowrap;
        justify-content: flex-start;
        align-items: center;

        svg {
            width: 18px;
            height: 18px;
            margin-right: 3px;
        }
    }

    .route-info:not(:first-of-type) {
        margin-top: 2px;
    }
}
