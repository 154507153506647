.stop-button {
    position: relative;
    display: flex;

    & > .button {
        text-align: left;
    }

    & > .selected {
        font-weight: bold;
    }

    & > .dot {
        margin-right: 18px;
        display: inline-block;

        &::before {
            content: '';
            position: absolute;
            border-left: 1px solid #d2d0ce;
        }

        &::after {
            content: '';
            border-radius: 50%;
            position: absolute;
        }
    }

    &:first-child > .dot, &:last-child > .dot {
        &::before {
            left: 6px;
            width: 2px;
        }

        &::after {
            top: calc(50% - 6px);
            height: 13px;
            width: 13px;
        }

        &.blue::after {
            background-color: #004e8c;
        }

        &.green::after {
            background-color: #0b6a0b;
        }

        &.red::after {
            background-color: #a4262c;
        }

        &.magento::after {
            background-color: #C239B3;
        }

        &.orange::after {
            background-color: #FF8C00;
        }
    }

    &:first-child > .dot::before {
        top: 50%;
        bottom: 0;
    }

    &:last-child > .dot::before {
        top: 0;
        bottom: 50%;
    }

    &:not(:first-child):not(:last-child) > .dot {
        &::before {
            top: 0;
            bottom: 0;
            left: 6px;
            width: 2px;
        }

        &::after {
            top: calc(50% - 6px);
            height: 9px;
            width: 9px;
            background-color: white;
        }

        &.blue::after {
            border: 2px solid #004e8c;
        }

        &.green::after {
            border: 2px solid #0b6a0b;
        }

        &.red::after {
            border: 2px solid #a4262c;
        }

        &.magento::after {
            border: 2px solid #C239B3;
        }

        &.orange::after {
            border: 2px solid #FF8C00;
        }
    }
}
