@import '../../index.module.scss';

:export {
    fadeTransitionDuration: $fade-transition-duration;
    offsetX: $popup-offset-x;
    offsetY: $popup-offset-y;
}

.vehicle-popup {
    .header {
        background-color: #00000010;
        padding: 6px 12px;
        font-weight: 600;
        display: flex;
        align-items: center;
        min-width: 186px;

        & > .label {
            margin-right: 12px;
        }
    }

    .body {
        padding: 12px;

        & > .info-row {
            display: table-row;

            & > div:first-of-type {
                display: table-cell;
                white-space: nowrap;
                vertical-align: top;
                color: #605e5c;
                padding-right: 12px;
            }

            & > div:last-of-type {
                display: table-cell;
                vertical-align: middle;
                word-break: break-all;
            }
        }

        &.loading {
            padding: 12px 24px;
        }
    }

    .footer:not(:empty) {
        padding: 0 12px 12px 12px;
    }
}
