@import '../../index.module.scss';

$trip-marker-zIndex: 1000;
$trip-marker-hidden-opacity: 0;
$trip-marker-visible-opacity: 1;

:export {
    zIndex: $trip-marker-zIndex;
    hiddenOpacity: $trip-marker-hidden-opacity;
    visibleOpacity: $trip-marker-visible-opacity;
    offsetX: $popup-offset-x;
    offsetY: $popup-offset-y;
}

.A-marker {
    background-size: cover;
    width: 100%;
    height: 100%;
    background-image: url('/assets/a-point-marker.svg');
}

.B-marker {
    background-size: cover;
    width: 100%;
    height: 100%;
    background-image: url('/assets/b-point-marker.svg');
}

.B-marker-eng {
    background-size: cover;
    width: 100%;
    height: 100%;
    background-image: url('/assets/b-point-marker-eng.svg');
}

.trip-popup {
    .header {
        background-color: #00000010;
        padding: 6px 12px;
        font-weight: 600;
    }

    .body {
        display: flex;
        justify-content: center;
        padding: 12px 12px 6px 12px;

        & > .switch-button {
            margin: 0 12px 8px 12px;
            align-self: flex-end;

            svg {
                width: 15px;
                height: 15px;
            }
        }

        & > .point-label {
            width: 50px;

            & > .title {
                font-size: 10px;
                text-align: center;
                margin-bottom: 3px;
            }

            & > .label {
                display: flex;
                border: 1px solid;
                border-radius: 50px;
                width: 29px;
                height: 29px;
                margin: auto;
                padding: 0;
                border-color: #d2d0ce;

                & > .letter {
                    font-size: 19px;
                    font-weight: 600;
                    margin-top: -3px;
                    color: #808080;
                }
            }

            & > .active-a {
                background: #d29200;
                border-color: #fbf4e5;

                & > .letter {
                    color: #fff;
                }
            }

            & > .active-b {
                background: #5c2d91;
                border-color: #efeaf4;

                & > .letter {
                    color: #fff;
                }
            }
        }
    }

    .footer {
        text-align: center;
        padding: 0 6px 6px 6px;
        width: 150px;
    }
}
