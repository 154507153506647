@import '../../index.module.scss';

.expanded-menu {
    border-right: 1px solid #d2d0ce;
    background-image: url('/assets/leftbar-background.png');
    padding: 12px;
    width: $left-bar-width - 1 + px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;

    & > .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        & > .logo {
            width: 159px;
            height: 53px;
        }

        & > .toggle-button {
            margin-top: 4px;

            svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    & > .toolbar {
        margin: 10px 0;
        display: flex;
        justify-content: center;

        & > .key-button {
            flex: 1;
            font-size: 15px;
            line-height: 1.5;

            &:not(:first-child) {
                margin-left: 4px;
            }

            &:not(:last-child) {
                margin-right: 4px;
            }

            svg {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }
    }
}
