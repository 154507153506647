@import '../../index.module.scss';

:export {
    fadeTransitionDuration: $fade-transition-duration;
}

.route-popup {
    .header {
        background-color: #00000010;
        padding: 6px 12px;
        font-weight: 600;
        display: flex;
        align-items: center;
        min-width: 186px;

        & > .label {
            margin-right: 12px;
        }
    }

    .body {
        padding: 12px;

        & > .title {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 12px;
            font-weight: 600;
            color: #605e5c;

            & > .part {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:not(:first-child) {
                    margin-left: 5px;
                }

                &:not(:last-child) {
                    margin-right: 5px;
                }
            }
        }

        & > .info-row {
            display: table-row;

            & > div {
                display: table-cell;
                white-space: nowrap;
                vertical-align: middle;

                &:first-of-type {
                    color: #605e5c;
                    padding-right: 12px;
                }
            }
        }
    }

    .footer:not(:empty) {
        padding: 0 12px 12px 12px;
    }
}
