$walking-line-grey-color: #605e5c;
$walking-line-dash-array-dash: 5;
$walking-line-dash-array-gap: 10;
$walking-line-opacity: 0.7;
$walking-line-weight: 4;

:export {
    grey: $walking-line-grey-color;
    dash: $walking-line-dash-array-dash;
    gap: $walking-line-dash-array-gap;
    opacity: $walking-line-opacity;
    weight: $walking-line-weight;
}

.popup {
    .header {
        background-color: #00000010;
        padding: 6px 12px;
        font-weight: 600;
    }

    .body {
        padding: 12px 12px 6px 12px;

        & > .distance-duration {
            justify-content: center;
        }
    }

    .footer {
        padding: 0 6px 6px 6px;
        width: 165px;
        text-align: center;
    }
}
