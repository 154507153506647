.distance-duration-info {
    display: flex;
    align-items: center;

    & > .info {
        display: flex;
        align-items: center;

        &:not(:first-child) {
            margin-left: 15px;
        }

        svg {
            height: 15px;
            width: 15px;
            margin-right: 5px;
            fill: #605e5c;
            display: flex;
        }
    }
}
