@import '../../../index.module.scss';

:export {
    fadeTransitionDuration: $fade-transition-duration;
}

.trip-info {
    border-left: 1px solid #d2d0ce;
    background-image: url('/assets/leftbar-background.png');
    width: $right-bar-width - 1 + px;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #b3b0ad #edebe9;
    display: flex;
    flex-direction: column;
    position: relative;

    & > .close-button {
        position: absolute;
        top: 12px;
        right: 12px;

        svg {
            width: 12px;
            height: 12px;
            display: flex;
        }
    }

    & > .walk-info {
        display: flex;
        padding: 12px;
        align-items: center;

        & > .arrow {
            width: 13px;

            svg {
                display: flex;
            }
        }

        & > .info {
            margin-left: 17px;
        }

        *[class*='WalkIcon_arrow'] {
            display: block !important;
        }
    }

    & > .path-info {
        &:not(:last-child) {
            border-bottom: 1px solid #00000010;
        }

        & > .title {
            margin: 12px 24px 4px 24px;
            font-weight: 600;
            color: #605e5c;
            display: flex;
            align-items: center;
            justify-content: center;

            & > .part {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin: 0 5px;
            }
        }

        & > .stops {
            padding: 2px 12px 12px 12px;
        }
    }
}
