.text-button {
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8a8886;

    &:hover:not(.disabled) {
        color: #3b3a39;

        svg {
            fill: #3b3a39;
        }
    }

    svg {
        fill: #8a8886;
        display: flex;
    }

    &.disabled {
        cursor: default;
    }
}
