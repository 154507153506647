@import '../../index.module.scss';

:export {
    slideTransitionDuration: $slide-transition-duration;
    fadeTransitionDuration: $fade-transition-duration;
}

.left-bar {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    scrollbar-width: thin;
    scrollbar-color: #b3b0ad #edebe9;

    & > :global(.slide) {
        @include slide-left($left-bar-width);
    }
}
