.stop-marker {
    background-size: cover;
    width: 100%;
    height: 100%;
    background-image: url('/assets/stop-marker.svg');

    &.blue {
        background-image: url('/assets/stop-marker-blue.svg');
    }

    &.green {
        background-image: url('/assets/stop-marker-green.svg');
    }

    &.red {
        background-image: url('/assets/stop-marker-red.svg');
    }

    &.magento {
        background-image: url('/assets/stop-marker-magento.svg');
    }

    &.orange {
        background-image: url('/assets/stop-marker-orange.svg');
    }

    &.point-a {
        background-image: url('/assets/stop-marker-point-a.svg');
    }

    &.point-b {
        background-image: url('/assets/stop-marker-point-b.svg');
    }
}
