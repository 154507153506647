.menu-button {
    text-align: center;
    padding: 5px 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    &:hover {
        background-color: #edebe9;
    }

    svg {
        fill: #605e5c;
        display: flex;
    }

    &.selected {
        background-color: #0078d4;
        color: white;

        &:hover {
            background-color: #106ebe;
        }

        svg {
            fill: white;
        }
    }
}
