.link-button {
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0078d4;

    &:hover {
        color: #005a9e;

        svg {
            fill: #005a9e;
        }
    }

    svg {
        fill: #0078d4;
        display: flex;
    }
}
