.error-modal {
    width: 100%;
    height: 100%;
    position: absolute;
    align-items: center;
    display: flex;
    justify-content: center;

    & > .content {
        width: 334px;
        padding: 24px;
        border-radius: 5px;
        border: 1px solid #d2d0ce;
        background-image: url('/assets/leftbar-background.png');
        display: flex;
        flex-direction: column;
        text-align: center;

        & > .header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 30px;

            & > .logo {
                width: 159px;
                height: 53px;
            }
        }

        & > .action {
            display: inline-block;
            margin: 20px auto 0 auto;
        }

        .error-status {
            color: #00000090;
            font-weight: 600;
            margin-right: 2px;
        }
    }
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 10000;
}
