.label {
    text-align: center;
    padding: 5px 7px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #d2d0ce;
    background-color: white;

    svg {
        fill: #605e5c;
        display: flex;
    }

    &.active {
        background-color: #f3f2f1;
        border-color: #bebbb8;
        font-weight: 600;
    }

    &.blue {
        background-color: #eaeff3; // #004e8c 10
        border: 1px solid #759ebf; // #004e8c 80
        color: #004e8c;

        svg {
            fill: #004e8c;
        }
    }

    &.green {
        background-color: #ecf2ec; // #0b6a0b 10
        border: 1px solid #7bad7b; // #0b6a0b 80
        color: #0b6a0b;

        svg {
            fill: #0b6a0b;
        }
    }

    &.red {
        background-color: #f5edee; // #a4262c 10
        border: 1px solid #cc898c; // #a4262c 80
        color: #a4262c;

        svg {
            fill: #a4262c;
        }
    }

    &.magento {
        background-color: #f5edee;
        border: 1px solid #CF8FFF;
        color: #C239B3;

        svg {
            fill: #C239B3;
        }
    }

    &.orange {
        background-color: #f5edee;
        border: 1px solid #FFBD50;
        color: #FF8C00;

        svg {
            fill: #FF8C00;
        }
    }
}
