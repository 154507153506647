.path-icon {
    width: 52px;
    text-align: center;

    & > .type, .time {
        font-size: 10px;
    }

    & > .type {
        margin-bottom: 3px;
        text-transform: capitalize;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap
    }

    & > .time {
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap
    }
}
