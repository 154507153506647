.progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    z-index: 2000;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #edebe9;
    }

    &::after {
        content: '';
        position: absolute;
        width: 33%;
        height: 2px;
        transition: width 0.3s ease 0s;
        background: linear-gradient(to right, #edebe9 0%, #0078d4 50%, #edebe9 100%);
        animation: progress 2s ease 0s infinite normal none running;
    }
}

@keyframes progress {
    0% {
        left: 0;
    }

    100% {
        left: 100%;
    }
}
