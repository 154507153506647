.route-header {
    display: flex;
    align-items: center;
    padding: 12px;
    background-color: #00000010;
    font-weight: 600;

    & > .info {
        margin-top: 16px;
        margin-left: 2px;
    }

    & > .close-button {
        align-self: flex-start;
        margin-left: auto;

        svg {
            width: 12px;
            height: 12px;
        }
    }
}
