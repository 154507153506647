$vehicle-marker-zIndex: 1000;
$vehicle-marker-hidden-opacity: 0;
$vehicle-marker-visible-opacity: 1;

:export {
    zIndex: $vehicle-marker-zIndex;
    hiddenOpacity: $vehicle-marker-hidden-opacity;
    visibleOpacity: $vehicle-marker-visible-opacity;
}

.vehicle-icon {
    visibility: collapse;
}

.vehicle-marker {
    width: 100%;
    height: 100%;

    & > .arrow {
        width: 100%;
        height: 100%;
        background-size: cover;
        transform-origin: center;

        &.blue {
            background-image: url('/assets/arrow-marker-blue.svg');
        }

        &.green {
            background-image: url('/assets/arrow-marker-green.svg');
        }

        &.red {
            background-image: url('/assets/arrow-marker-red.svg');
        }

        &.magento {
            background-image: url('/assets/arrow-marker-magento.svg');
        }

        &.orange {
            background-image: url('/assets/arrow-marker-orange.svg');
        }
    }

    & > .vehicle {
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 60%;
        height: 60%;
        margin: auto;

        &.bus {
            &.blue {
                background-image: url('/assets/bus-marker-blue.svg');
            }

            &.green {
                background-image: url('/assets/bus-marker-green.svg');
            }

            &.red {
                background-image: url('/assets/bus-marker-red.svg');
            }

            &.magento {
                background-image: url('/assets/bus-marker-magento.svg');
            }

            &.orange {
                background-image: url('/assets/bus-marker-orange.svg');
            }
        }

        &.troll {
            &.blue {
                background-image: url('/assets/troll-marker-blue.svg');
            }

            &.green {
                background-image: url('/assets/troll-marker-green.svg');
            }

            &.red {
                background-image: url('/assets/troll-marker-red.svg');
            }

            &.magento {
                background-image: url('/assets/troll-marker-magento.svg');
            }

            &.orange {
                background-image: url('/assets/troll-marker-orange.svg');
            }
        }
    }
}
