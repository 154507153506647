.city-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
}

.city-group {
    display: flex;
    justify-content: center;
    width: 100%;

    & > .col {
        width: 33.33%;
        text-align: center;

        &:not(:first-child) {
            padding-left: 8px;
        }

        &:not(:last-child) {
            padding-right: 8px;
        }

        & > .city-btn {
            display: inline-flex;
        }
    }
}
