.map-title {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    background: rgba(255, 255, 255, 0.7);
    font-weight: 600;
    padding: 0 5px 2px 5px;
    color: #333;
    z-index: 999;
}
