@import '../../index.module.scss';

:export {
    slideTransitionDuration: $slide-transition-duration;
}

.right-bar {
    height: 100%;
    overflow: hidden;

    & > :global(.slide) {
        @include slide-right($right-bar-width);
    }
}
