﻿$left-bar-width: 360;
$right-bar-width: 340;
$popup-offset-x: 0;
$popup-offset-y: 0;

:export {
    leftBarWidth: $left-bar-width;
    rightBarWidth: $right-bar-width;
    offsetX: $popup-offset-x;
    offsetY: $popup-offset-y;
}

$fade-transition-duration: 300;
$slide-transition-duration: 800;

:global(.fade-in) {
    &-enter {
        opacity: 0;

        &-active {
            opacity: 1;
            transition: opacity $fade-transition-duration + ms;
        }
    }

    &-exit {
        display: none;
    }
}

:global(.fade-in-out) {
    &-enter {
        opacity: 0;

        &-active {
            opacity: 1;
            transition: opacity $fade-transition-duration + ms;
        }
    }

    &-exit {
        opacity: 1;

        &-active {
            opacity: 0;
            transition: opacity $fade-transition-duration + ms;
        }
    }
}

@mixin slide-left($width) {
    &-enter {
        margin-left: -$width + px;
        overflow: hidden;

        &-active {
            margin-left: 0;
            overflow: hidden;
            transition: margin-left $slide-transition-duration + ms;
        }
    }

    &-exit {
        margin-left: 0;
        overflow: hidden;

        &-active {
            margin-left: -$width + px;
            overflow: hidden;
            transition: margin-left $slide-transition-duration + ms;
        }
    }
}

@mixin slide-right($width) {
    &-enter {
        margin-right: -$width + px;
        overflow: hidden;

        &-active {
            margin-right: 0;
            overflow: hidden;
            transition: margin-right $slide-transition-duration + ms;
        }
    }

    &-exit {
        margin-right: 0;
        overflow: hidden;

        &-active {
            margin-right: -$width + px;
            overflow: hidden;
            transition: margin-right $slide-transition-duration + ms;
        }
    }
}

@mixin slide-left-relative {
    &-enter {
        transform: translateX(100%);
        overflow: hidden;

        &-active {
            transform: translateX(0);
            overflow: hidden;
            transition: transform $slide-transition-duration + ms;
        }
    }

    &-exit {
        transform: translateX(0);
        overflow: hidden;

        &-active {
            transform: translateX(-100%);
            overflow: hidden;
            transition: transform $slide-transition-duration + ms;
        }
    }
}

@mixin slide-right-relative {
    &-enter {
        transform: translateX(-100%);
        overflow: hidden;

        &-active {
            transform: translateX(0);
            overflow: hidden;
            transition: transform $slide-transition-duration + ms;
        }
    }

    &-exit {
        transform: translateX(0);
        overflow: hidden;

        &-active {
            transform: translateX(100%);
            overflow: hidden;
            transition: transform $slide-transition-duration + ms;
        }
    }
}
