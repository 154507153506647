.button {
    text-align: center;
    padding: 5px 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #d2d0ce;
    background-color: white;

    &:hover:not(.disabled) {
        background-color: #edebe9;
    }

    svg {
        fill: #605e5c;
        display: flex;
    }

    &.active {
        background-color: #f3f2f1;
        border-color: #bebbb8;
        font-weight: 600;

        &:hover:not(.disabled) {
            background-color: #e1dfdd;
        }
    }

    &.selected {
        background-color: #0078d4;
        border-color: #0078d4;
        color: white;
        font-weight: 600;

        &:hover:not(.disabled) {
            background-color: #106ebe;
        }

        svg {
            fill: white;
        }
    }

    &.blue {
        background-color: #eaeff3; // #004e8c 10
        border-color: #759ebf; // #004e8c 80
        color: #004e8c;
        font-weight: 600;

        &:hover:not(.disabled) {
            background-color: #ccdbe6; // #004e8c 20
        }

        svg {
            fill: #004e8c;
        }
    }

    &.green {
        background-color: #ecf2ec; // #0b6a0b 10
        border-color: #7bad7b; // #0b6a0b 80
        color: #0b6a0b;
        font-weight: 600;

        &:hover:not(.disabled) {
            background-color: #cee0ce; // #0b6a0b 20
        }

        svg {
            fill: #0b6a0b;
        }
    }

    &.red {
        background-color: #f5edee; // #a4262c 10
        border-color: #cc898c; // #a4262c 80
        color: #a4262c;
        font-weight: 600;

        &:hover:not(.disabled) {
            background-color: #ebd3d4; // #a4262c 20
        }

        svg {
            fill: #a4262c;
        }
    }

    &.magento {
        background-color: #f5edee;
        border-color: #CF8FFF;
        color: #C239B3;
        font-weight: 600;

        &:hover:not(.disabled) {
            background-color: #CFDFFF;
        }

        svg {
            fill: #C239B3;
        }
    }

    &.orange {
        background-color: #f5edee;
        border-color: #FFBD50;
        color: #FF8C00;
        font-weight: 600;

        &:hover:not(.disabled) {
            background-color: #FFFFA0;
        }

        svg {
            fill: #FF8C00;
        }
    }

    &.disabled {
        background: none;
        border: none;
        cursor: initial;
    }
}
