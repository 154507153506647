.walk-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    max-width: 28px;

    & > .time {
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    svg {
        display: flex;
        stroke: #605e5c;
    }
}
