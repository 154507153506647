.group-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -2px;
    margin-top: -2px;

    & > * {
        width: calc(20% - 2px);
        margin-top: 2px;
        margin-left: 2px;
    }
}
