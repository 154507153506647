.header {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  color: #605e5c;
  
  &-bold {
    font-weight: 600;
  }
}

.item {
  display: flex;
  justify-content: space-between;
  padding: 6px;
}