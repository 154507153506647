@import '../../../index.module.scss';

:export {
    fadeTransitionDuration: $fade-transition-duration;
}

.search-form {
    position: relative;

    & > .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > .clear-button {
            white-space: nowrap;
            display: flex;
            align-items: center;

            svg {
                width: 10px;
                height: 10px;
                margin-right: 5px;
            }
        }

        &:not(:first-child) {
            margin-top: 10px;
        }
    }

    & > .hint {
        margin-top: 10px;
    }

    & > .input-section {
        position: relative;

        & > input {
            padding: 5px;
            box-sizing: border-box;
            border: 1px solid #d2d0ce;
            border-radius: 5px;
            width: 100%;
            line-height: 1.5;

            &:focus {
                outline: none;
            }

            &::placeholder {
                font-style: italic;
            }
        }

        & > .A-point, .B-point, .stop {
            padding-left: 33px;

            &:focus {
                padding-left: 5px;
            }
        }

        & > .clear-button {
            position: absolute;
            right: 6px;
            bottom: 6px;
            cursor: pointer;

            svg {
                width: 10px;
                height: 10px;
                fill: #d2d0ce;
            }

            &:hover {
                svg {
                    fill: #3b3a39;
                }
            }
        }

        & > .point-icon, .stop-icon {
            position: absolute;
            left: 3px;
        }

        & > .point-icon {
            top: 2px;

            svg {
                width: 27px;
                height: 27px;
            }
        }

        & > .stop-icon {
            top: 4px;

            svg {
                width: 24px;
                height: 24px;
            }
        }

        & > .places {
            margin-top: 2px;
            box-sizing: border-box;
            border: 1px solid #d2d0ce;
            border-radius: 5px;
            width: 100%;
            background: #ffffff;
            position: absolute;
            z-index: 2000;
            box-shadow: 0 1.6px 3.6px 0 rgba(0,0,0,.132), 0 0.3px 0.9px 0 rgba(0,0,0,.108);

            & > .item {
                cursor: pointer;
                padding: 5px;
                box-sizing: border-box;
                text-overflow: ellipsis;
                overflow-x: hidden;
                white-space: nowrap;

                &.highlighted {
                    background: #edebe9;
                }

                &.selected {
                    font-weight: 600;
                }
            }
        }
    }

    & > .switch-button {
        position: absolute;
        right: 3px;
        top: 80px;
        z-index: 1000;

        svg {
            width: 15px;
            height: 15px;
            transform: rotate(90deg);
        }
    }
}
