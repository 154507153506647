@import '../../../index.module.scss';

:export {
    slideTransitionDuration: $slide-transition-duration;
    fadeTransitionDuration: $fade-transition-duration;
}

.route-info {
    border-left: 1px solid #d2d0ce;
    background-image: url('/assets/leftbar-background.png');
    width: $right-bar-width - 1 + px;
    height: 100%;
    display: flex;
    flex-direction: column;

    & > .direction {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        position: relative;

        & > .prev-button, & > .next-button {
            position: absolute;
            top: 12px;

            &.prev-button {
                left: 3px;
            }

            &.next-button {
                right: 3px;
            }

            svg {
                width: 16px;
                height: 16px;
            }
        }

        & > .content {
            display: flex;
            flex-direction: column;
            overflow-y: auto;

            & > .title {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 12px 24px 4px 24px;
                font-weight: 600;
                color: #605e5c;

                & > .part {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin: 0 5px;
                }
            }

            & > .stops {
                padding: 2px 12px;
                overflow-y: auto;
                scrollbar-width: thin;
                scrollbar-color: #b3b0ad #edebe9;
            }

            &:global(.slide-left) {
                @include slide-left-relative;

                &-enter > .stops, &-enter-active > .stops, &-exit > .stops, &-exit-active > .stops {
                    overflow: hidden;
                }

                &-exit, &-exit-active {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                }
            }

            &:global(.slide-right) {
                @include slide-right-relative;

                &-enter > .stops, &-enter-active > .stops, &-exit > .stops, &-exit-active > .stops {
                    overflow: hidden;
                }

                &-exit, &-exit-active {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                }
            }
        }
    }
}
