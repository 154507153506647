@import '../../../index.module.scss';

:export {
    fadeTransitionDuration: $fade-transition-duration;
}

.navigation-menu {
    display: flex;
    flex-direction: column;
    flex: 1;

    & > .expand-button {
        white-space: nowrap;
        display: flex;
        align-items: center;
        font-weight: 600;
        margin-top: 5px;

        svg {
            width: 10px;
            height: 10px;
            margin-right: 5px;
            transform: rotate(90deg);
        }
    }

    & > .notification {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: auto;
        margin-bottom: auto;

        svg {
            width: 40px;
            height: 40px;
            fill: #d2d0ce;
            display: flex;
            margin: auto;
        }

        & > .hint {
            margin-top: 5px;
        }
    }

    .trip-info:not(:last-of-type) {
        margin-bottom: 2px;
    }
}
